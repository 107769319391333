<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('news.newsAction') }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <v-card-title class="py-1">
            <h5 style="max-width: 220px;" :class="newsId ? 'px-1 text-truncate font-italic primary--text font-weight-bold' : 'primary--text text--darken-1'" class="font-weight-regular">{{ newsId ? originModel.name && `${newsId}. ${originModel.name}` : $t('news.addNews') }}</h5>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="primary"
              small
              :loading="loading"
              :disabled="!valid || (!!model.is_locked && model.user_id!==user.id)"
              @click="saveNews"
            >
              {{ $t('roles.save') }}
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-h5">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="6">
                  <label class="body-2">{{ $t('roles.description') }}</label>
                  <TiptapVuetify
                    v-model="model.description"
                    :extensions="extensions"
                    @keydown="onInit"
                  />
                </v-col>
                <v-col cols="3">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="formattedNewsDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        hide-details="auto"
                        v-model="model.news_date"
                        :label="$t('news.newsDate')"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formattedNewsDate"
                      @change="$refs.startDateMenu.save(formattedNewsDate)"
                    >
                      <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import {changedFormData} from '@/utils';
import { formatDateDDMMYYYY, todayDate } from '../../../utils';
import { TiptapVuetify, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
 
export default {
  name: 'NewsAction',
  data() {
    return {
      loading: false,
      valid: false,
      model: {
        original_description: ""
      },
      originModel: {},
      startDateMenu: false,
      formattedNewsDate:'',
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        // [Heading, {
        //   options: {
        //     levels: [1, 2, 3]
        //   }
        // }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      content: ``
    }
  },
  components: {
    TiptapVuetify
  },
  computed: {
    newsId() {
      return this.$route.params.id
    },
    ...mapGetters({
      newsById: 'newsById',
      newsQuery: 'newsQuery',
      user: 'user',
      profileById: 'profile/profileById'
    }),
  },
  async mounted() {    
    if (this.$route.params.id) {
      await this.$store.dispatch('GetNewsById', this.$route.params.id);
    }
    this.model.original_description = this.model.description
  },
  watch: {
    newsById() {
      if (Object.keys(this.newsById).length > 0) {
        this.model = Object.assign({}, this.model, this.newsById)
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    formattedNewsDate() {
      this.model.news_date = this.formattedNewsDate
    },
  },
  async created() {
    window.addEventListener('beforeunload', this.handler)
    this.model.original_description = this.model.description
  },
  beforeDestroy() {
    this.$refs.form ? this.$refs.form.reset() : '';
    this.model = {},
    this.$store.commit('SET_NEWS_BY_ID', {});
    this.$store.commit('SET_USER_LOGOUT', false)
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    formatDateDDMMYYYY,
    todayDate,
    async handler(event) {
      event.preventDefault();
    },
    callBackFunction() {
      this.$router.push({
        name: "News",
        query: {
          ...this.newsQuery,
        },
      });
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return !v || (v && v.length <= sizeValue) || this.$t('formRules.lengthValidMessage', { fieldName: fieldValue, type: typeValue, size: sizeValue });
    },
    onInit(event) {
      this.model.original_description = event.target.outerText.trim()
    },
    async saveNews() {
      if(this.$refs.form.validate()){
        this.loading = true;
        let data;
        if(this.$route.params.id) {
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store.dispatch('EditNews', {company_id: this.$route.params.id, company: saveData}).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.$store.dispatch('GetNewsById', data.data.id);
          }
        } else {
          data = await this.$store.dispatch('SetNews', this.model).catch((e) => {
            this.loading = false;
            console.log(e)
          });
          if(data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.$router.push('/news/editNews/' + data.data.id).catch(()=>{});
            await this.$store.dispatch('GetNewsById', data.data.id);
          }
        }
      }
    }
  }
}
</script>
